import { BreadcrumbNavigation, Header } from "@/components";
import { getRoute } from "@/getters/getRoute";

export default function ErrorPage() {
  return (
    <>
      <BreadcrumbNavigation items={[{ label: "404 - Page not found" }]} />
      <Header
        className="not-found-page bg-primary text-white"
        description={{ path: `page.error.description`, placeholder: "<h2>We can't find this page but we can find you great jobs and great staff instead.</h2>"}
        }
        cta={{ label: "Return to homepage", href: "/", variant: null }}
        img={{ path: "page.error.img", src: "/assets/home/404.png" }}
        variant={"50/50"}
      />
    </>
  );
}
