import dynamic from "next/dynamic";

export const Accordion = dynamic(() => import("./Accordion"));
export const ArticleFeed = dynamic(() => import("./ArticleFeed"));
export const Benefits = dynamic(() => import("./Benefits"));
export const BlockQuote = dynamic(() => import("./BlockQuote"));
export const BranchLookupPanel = dynamic(() => import("./BranchLookupPanel"));
export const BranchList = dynamic(() => import("./BranchList"));
export const BrandPositioning = dynamic(() => import("./BrandPositioning"));
export const BreadcrumbNavigation = dynamic(() => import("./BreadcrumbNavigation"));
export const CaseStudies = dynamic(() => import("./CaseStudies"));
export const CaseStudiesFeed = dynamic(() => import("./CaseStudiesFeed"));
export const Chatbot = dynamic(() => import("./Chatbot"));
export const CollapsibleContent = dynamic(() => import("./CollapsibleContent"));
export const CollapsibleSection = dynamic(() => import("./CollapsibleSection"));
export const Divider = dynamic(() => import("./Divider"));
export const Features = dynamic(() => import("./Features"));
export const FeaturedEmployers = dynamic(() => import("./FeaturedEmployers"));
export const FeaturedEmployersCarousel = dynamic(() => import("./FeaturedEmployersCarousel"));
export const FeatureTabsList = dynamic(() => import("./FeatureTabsList"));
export const Form = dynamic(() => import("./Form"));
export const GridButtonsGrid = dynamic(() => import("./GridButtonsGrid"));
export const Header = dynamic(() => import("./Header"));
export const Hero = dynamic(() => import("./HeroV2"));
export const HeroCarousel = dynamic(() => import("./HeroCarousel"));
export const HorizontalListings = dynamic(() => import("./HorizontalListings"));
export const JobsFeed = dynamic(() => import("./JobsFeed"));
export const KeyTabListing = dynamic(() => import("./KeyTabListing"));
export const LatestBlogs = dynamic(() => import("./LatestBlogs"));
export const LatestJobs = dynamic(() => import("./LatestJobs"));
export const LogoCarousel = dynamic(() => import("./LogoCarousel"));
export const MiniCarousel = dynamic(() => import("./MiniCarousel"));
export const MultiTiles = dynamic(() => import("./MultiTiles"));
export const MultiCards = dynamic(() => import("./MultiCards"));
export const NarrativePanel = dynamic(() => import("./NarrativePanel"));
export const OfficeLocations = dynamic(() => import("./BranchLocations"));
export const PromoButtons = dynamic(() => import("./PromoButtons"));
export const PromoSection = dynamic(() => import("./PromoSection"));
export const CareerAdviceContent = dynamic(() => import("./CareerAdviceContent"));
export const PromotionalCarousel = dynamic(() => import("./PromotionalCarousel"));
export const PromotionalPanel = dynamic(() => import("./PromotionalPanel"));
export const QuickJobSearch = dynamic(() => import("./QuickJobSearch"));
export const Recruiter = dynamic(() => import("./Recruiter"));
export const RecruiterListing = dynamic(() => import("./RecruiterListing"));
export const RelatedJobs = dynamic(() => import("./RelatedJobs"));
export const RichText = dynamic(() => import("./RichText"));
export const SectionHeading = dynamic(() => import("./SectionHeading"));
export const SimilarJobs = dynamic(() => import("./SimilarJobs"));
export const SocialVideo = dynamic(() => import("./SocialVideo"));
export const Spacer = dynamic(() => import("./Spacer"));
export const StatCounterWidget = dynamic(() => import("./StatCounterWidget"));
export const TeamBio = dynamic(() => import("./TeamBio"));
export const TileGroups = dynamic(() => import("./TileGroups"));
export const TrendingJobs = dynamic(() => import("./TrendingJobs"));
export const Title = dynamic(() => import("./Title"));
export const InfoList = dynamic(() => import("./InfoList"));
